<template>
    <div class="help-page">
        <div v-html="content">

        </div>
    </div>
</template>

<script>
    import {PostApi} from '@/api'

    export default {
        name: "Help",
        data() {
            return {
                title: "",
                content: "",
            }
        },
        created() {
            this.loadPageData();
        },
        methods: {
            loadPageData() {
                let payload = {
                    name: "help_info"
                }
                PostApi.getNewsDetail(payload).then((respData) => {
                    if (respData.c === 0) {
                        this.title = respData.title;
                        this.content = respData.data.content;
                    }
                }).catch((err) => {
                    // ToastUI.clearToast();
                });
            },
        }

    }
</script>

<style scoped>

    .help-page{
        width: 750px;
        min-height: 640px;
        margin: 30px auto;
    }
</style>